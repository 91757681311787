import './App.css';
import CountdownTimer from './components/count-down';

function App() {
  return (
    <div className="">
      <CountdownTimer />   
    </div>
  );
}

export default App;
