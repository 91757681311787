// App.js
import React, { useEffect, useState } from 'react';
import FlipCard from './flip-card.jsx';

function CountdownTimer() {
    const [timeBetweenDates, setTimeBetweenDates] = useState(0);

    useEffect(() => {
        const countToDate = new Date().setHours(new Date().getHours() + 24);
        let previousTimeBetweenDates;

        const interval = setInterval(() => {
            const currentDate = new Date();
            const newTimeBetweenDates = Math.ceil((countToDate - currentDate) / 1000);

            if (newTimeBetweenDates !== previousTimeBetweenDates) {
                setTimeBetweenDates(newTimeBetweenDates);
                previousTimeBetweenDates = newTimeBetweenDates;
            }
        }, 250);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="container">
            <div className="container-segment">
                <div className="segment-title">Hours</div>
                <div className="segment">
                    <FlipCard value={Math.floor(timeBetweenDates / 3600 / 10)} />
                    <FlipCard value={Math.floor(timeBetweenDates / 3600) % 10} />
                </div>
            </div>
            <div className="container-segment">
                <div className="segment-title">Minutes</div>
                <div className="segment">
                    <FlipCard value={Math.floor((timeBetweenDates / 60) % 60 / 10)} />
                    <FlipCard value={Math.floor((timeBetweenDates / 60) % 10)} />
                </div>
            </div>
            <div className="container-segment">
                <div className="segment-title">Seconds</div>
                <div className="segment">
                    <FlipCard value={Math.floor(timeBetweenDates % 60 / 10)} />
                    <FlipCard value={timeBetweenDates % 10} />
                </div>
            </div>
        </div>
    );
}

export default CountdownTimer;
